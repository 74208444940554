<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('searchArea.datetype') }}</span></div>
          <div class="fx">
            <date-selector :class="'searchbar-content'"
                           @setStartDate="setStartDate"
                           @setEndDate="setEndDate"
                           :startDefault="startDefault"
                           :endDefault="endDefault"
                           :configs="dateConfigs"
                           :isOldYn="true"
                           @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
          </div>
        </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{$t('table.head.betType')}}</span></div>
          <select class="reqMember" v-model="reqData.vendorCode">
            <option value="">전체</option>
            <template v-for="item in gameList" :key="item.groupCode">
              <option :value="item.code">{{ item.codeName }}</option>
            </template>
          </select>
        </div>
        <div :class="'searchbar-content'">
          <div class="title"><span>{{ $t('table.head.category') }}</span></div>
          <div>
            <input type="text" class="mr-5" :placeholder="$t('common.id')" v-model="reqData.memId" />
            <input type="text" class="mr-5" :placeholder="$t('table.head.gameIdx')" v-model="reqData.betId" />
            <button class="btn-search" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>

        <div class="searchbar-content search-check">
           <div class="title"><span>{{$t('common.detailCondi')}} :</span></div>
           <div>
             <label for="">{{$t('common.all')}}</label><input type="checkbox" :checked="reqData.typeList.length === 8" @change="onChangeTypeList('all')"/>
             <label for="">{{$t('header.betPt')}}</label><input type="checkbox" @change="onChangeTypeList('3')" :checked="reqData.typeList.includes('3')" />
             <label for="">{{$t('table.body.adminIn')}}</label><input type="checkbox" :checked="reqData.typeList.includes('2')" @change="onChangeTypeList('2')"/>
             <label for="">{{$t('table.body.adminOut')}}</label><input type="checkbox" :checked="reqData.typeList.includes('-2')" @change="onChangeTypeList('-2')"/>
             <label for="">{{$t('searchArea.uPointEx')}}</label><input type="checkbox" :checked="reqData.typeList.includes('-4')" @change="onChangeTypeList('-4')"/>
             <label for="">{{$t('searchArea.aPointEx')}}</label><input type="checkbox" :checked="reqData.typeList.includes('-44')" @change="onChangeTypeList('-44')"/>
             <label for="">{{$t('table.head.bonuss')}}</label><input type="checkbox" :checked="reqData.typeList.includes('5') && reqData.typeList.includes('6') && reqData.typeList.includes('7')" @change="onChangeTypeList('bonus')"/>
           </div>
        </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrap">
      <!--<div class="table-wrapper mrl">
        <table class="mainTable totalTable">
          <tbody>
            <template v-for="(item, key) in pointTotal" :key="key">
              <tr>
                <th class="product-title group">{{ $t(`gameList.${key}`) }}</th>
                <td>{{ item.totalPmt }}</td>
                <template v-for="(child, idx) in item.groupList" :key="idx">
                  <th class="product-title">{{ child.codeName }}</th>
                  <td>{{ child.pmtCommas }}</td>
                </template>
                <template v-if="item.colspan !== 0">
                  <td :colspan="item.colspan"></td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>-->
      <div class="main-contents-wrapper">
        <article class="total-info-container fx-col-sbw">
          <div class="total-info-wrap">
            <div class="total-info-item">
              <img :src="require('@/assets/img/searchicon.png')" />
              <i class="fas fa-search"></i>
              <span>{{ $t('searchArea.schResult') }}</span>
            </div>
            <div class="total-info-item">
              <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span v-if="pageInfo" class="value">{{ pageInfo.tatal_list_count }}</span>
            </div>
            <div class="total-info-item">
              <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value" v-if="pageInfo">{{ pageInfo.page }}</span>
            </div>
            <div class="total-info-item">
              <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value" v-if="pageInfo">{{ pageInfo.tatal_page_count + 1 }}</span>
            </div>
          </div>
          <!-- <div class="total-info-wrap">
            <button type="button" class="btn-layout btn-mint">다운로드</button>
          </div> -->
        </article>
        <div class="table-wrapper">
          <table class="mainTable">
            <colgroup>
              <col width="3%" />
              <col width="5%" />
              <col width="5%" />
              <col width="3%" />
              <col width="13%" />
              <col width="3%" />
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="10%" />
              <col width="8%" />
              <col width="8%" />
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
            </colgroup>
            <table-head :headInfo="headInfo" />
            <tbody v-if="pointList.length !== 0">
            <tr v-for="(item, idx) in pointList" :key="idx">
              <td class="roboto">{{ Number(this.pageInfo.tatal_list_count) - ((this.pageInfo.page - 1) * 30 + idx) }}</td>
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
              </td>
              <td>
                <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">{{ item.memNick }}</button>
              </td>
              <td :class="item.partnerLevel">
                <span class="box">{{ computedPartnerLevel(item) }}</span>
              </td>
              <td>
                 <div v-if="item.partnerLevel !== 'NORMAL'">
                    <div class="topwrap" v-if="item.betMemId !== '-'" @click="onDetailOpen(item)">
                      <a class="topBtn" :class="{'on' : item.isTopListOpen }"></a>
                      <div class="top">
                        <span class="topbox">[{{item.betMemLevelName}}] </span>
                        <span><em>{{item.betMemId}}</em> (<i class="fc-red">{{item.betMemRate}}</i>%) </span>
                      </div>
                      <ul v-if="item.isTopListOpen" class="bottom">
                        <template v-for="(user) in item.topList" :key="user.memId">
                          <li :value="user.memId" class="topuser">
                            <span class="topbox" :class="user.partnerLevel">[{{user.partnerLevelName}}] </span>
                            <span><em :class="user.partnerLevel">{{user.memId}}</em> (<i class="fc-red">{{user.rate}}</i>%) </span>
                          </li>
                        </template>
                      </ul>
                    </div>
                 </div>
              </td>
              <td>{{ item.pointTypeName }}</td>

              <td v-if="item.isRollingPoint" class="roboto">{{ item.memRate }}%</td>
              <td v-else>-</td>

              <td v-if="item.isRollingPoint"><span class="game-kind">{{item.gameCategory}}</span></td>
              <td v-else>-</td>

              <td v-if="item.isRollingPoint"><span>{{ item.vendorName }}</span></td>
              <td v-else>-</td>

              <td v-if="item.isRollingPoint"><span>{{ item.gameName }}</span></td>
              <td v-else>-</td>

              <td v-if="item.isRollingPoint">
                 <div class="copy">
                    <span class="text-space">{{ item.betId }}</span>
                    <a class="copybtn" @click="copyToClipboard(item.betId)"></a>
                 </div>
              </td>
              <td v-else>-</td>

              <td v-if="item.isRollingPoint"><span class="text-space roboto">{{ numberWithCommas(item.betAmt) }}</span></td>
              <td v-else>-</td>

              <td class="roboto">{{ numberWithCommas(item.prePointAmt) }}</td>
              <td>
                 <span :class="Number(item.pointAmt) > 0 ? 'i-win':'i-lose'" class="roboto">{{ numberWithCommas(item.pointAmt) }}</span>
              </td>
              <td class="roboto">{{ numberWithCommas(Number(item.pointAmt) + Number(item.prePointAmt)) }}</td>
              <td class="roboto">{{ replaceDateT(item.updDate) }}</td>
            </tr>
            </tbody>
            <template v-else>
              <td colspan="16">{{ $t('txt.noData') }}</td>
            </template>
          </table>
        </div>
      </div>
    </div>
    <pagination v-if="pointList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import TableHead from '@/components/main/table/Head.vue'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import Memo from '@/components/common/memo'
import typing from '@/components/ui/Typing'
import UiSelect from '@/components/ui/Select'
import { getCode, memberBotPointList, memberPointList } from '@/api/member.js'
import { getDateStr, numberWithCommas, replaceDateT } from '@/libs/utils.js'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'userPointList',
  components: {
    TableHead,
    SearchFilter,
    DateSelector,
    Pagination,
    Memo,
    UiSelect,
    typing
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['idx', 'memId', 'memNick', 'level', 'downRoll', 'detail', 'rollPer', 'gameType', 'gamecompany', 'gameName', 'gameIdx', 'betAmt', 'pre', 'afterPoint', 'aftm', 'applyTime'] // 누적포인트 합계 부분 삭제
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        typeList: ['3', '2', '-2', '-4', '-44', '5', '6', '7'],
        memId: '',
        pointStatus: '',
        startDate: '',
        endDate: '',
        vendorCode: '',
        oldYn: 'N'
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      pointList: [],
      gameList: [],
      groupCode: [],
      pageInfo: {},
      totalInfo: {},
      pointTotal: {}
    }
  },
  methods: {
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          alert('클립보드에 복사했습니다')
        })
    },
    onChangeTypeList (input) {
      if (input === 'all') {
        if (this.reqData.typeList.length === 0) {
          this.reqData.typeList = ['3', '2', '-2', '-4', '-44', '5', '6', '7']
        } else {
          this.reqData.typeList = []
        }
      } else {
        if (input === 'bonus') {
          if (this.reqData.typeList.includes('5')) {
            const typeList = [
              ...this.reqData.typeList
            ]
            for (let i = typeList.length - 1; i > -1; i--) {
              const value = typeList[i]
              if (value === '5' || value === '6' || value === '7') {
                this.reqData.typeList.splice(i, 1)
              }
            }
          } else {
            this.reqData.typeList.push('5')
            this.reqData.typeList.push('6')
            this.reqData.typeList.push('7')
          }
        } else {
          if (this.reqData.typeList.length === 0) {
            this.reqData.typeList.push(input)
          } else {
            let isSame = false
            this.reqData.typeList.forEach((value, index) => {
              if (value === input) {
                isSame = true
                this.reqData.typeList.splice(index, 1)
              }
            })
            if (!isSame) {
              this.reqData.typeList.push(input)
            }
          }
        }
      }
    },
    replaceDateT,
    numberWithCommas,
    onDetailOpen (item) {
      if (!item.isTopListOpen) {
        if (item.topList.length === 1) {
          this.emitter.emit('Loading', true)
          const params = {
            memId: item.memId,
            betMemId: item.betMemId,
            vendorCode: item.vendorCode
          }

          if (item.rateTypeCode) {
            if (item.rateTypeCode === '-') {
              params.rateTypeCode = ''
            } else {
              params.rateTypeCode = item.rateTypeCode
            }
          }

          console.log('[REQ]detail : ', params)

          memberBotPointList(params).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              item.topList = []

              res.data.botRateList.forEach(user => {
                item.topList.push({
                  memId: user.memId,
                  rate: user.rate,
                  partnerLevelName: user.partnerLevelName,
                  partnerLevel: user.partnerLevel
                })
              })
            }
            this.emitter.emit('Loading', false)
            item.isTopListOpen = true
          })
        } else {
          item.isTopListOpen = true
        }
      } else {
        item.isTopListOpen = false
      }
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
        _date.setSeconds(0)
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    pageSeach () {
      this.setTableData(1)
    },
    async getGameList () {
      const gameList = await getCode('kplay')
      this.gameList = gameList
      const groupArr = []
      for (const item of gameList) {
        if (groupArr.indexOf(item.groupCode) === -1) {
          groupArr.push(item.groupCode)
        }
      }
      // console.log(groupArr);
      this.groupCode = groupArr
    },
    async setTableData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      }
      console.log('point req : ', this.reqData)
      const data = this.reqData
      const listRes = await memberPointList(data)
      console.log('pointlist : ', listRes)
      this.pageInfo = listRes.data.pageInfo
      this.pointList = listRes.data.list

      this.pointList.forEach(item => {
        item.isRollingPoint = false
        if (item.pointType === '3') {
          item.isRollingPoint = true
        }
        if (item.memId !== item.betMemId) {
          item.isTopListOpen = false
          item.topList = []
          item.topList.push({
            memId: item.betMemId,
            rate: item.betMemRate
          })
        }
      })

      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.setStartDate()
    this.setEndDate()
    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    await this.getGameList()
    await this.setTableData()
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.mainTable td {overflow: initial;}

.topwrap {border-radius: 2px;font-size: 11px;box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);background-image: linear-gradient(to bottom, #fff 19%, #e9f0f6);border: solid 1px #808080;position: relative;width: 85%;margin: 0 auto;}
.topwrap .topBtn {width: 12px;height: 12px;border-radius: 2px;background-color: #696969;color: #fff;font-size: 14px;font-weight: bold;display: flex;align-items: center;justify-content: center;position: absolute;right: 5px;top: 5px;}
.topwrap .topBtn::before {content: '+';}
.topwrap .topBtn.on::before {content: '-';}
.topwrap .top {height: 22px;padding: 0 17px 0 7px;display: flex;align-items: center;}
.topwrap .bottom {position: absolute;left: -1px;width: 100%;z-index: 2;border-radius: 2px;box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);background-image: linear-gradient(to bottom, #fff 19%, #e9f0f6);border: solid 1px #808080;}
.topuser {height: 22px;display: flex;align-items: center;padding-left: 7px;}
.topuser:hover {background: #1967d2;color: #fff !important;}
.topuser:hover span, .topuser:hover em, .topuser:hover i {color: #fff !important;}

.box {display: inline-block; max-width: 57px;padding: 4px 10px;font-size: 12px;border-radius: 2px;color: #fff;border: 1px solid #000000;box-sizing: border-box;}
.NORMAL .box {background: #df663a;}
.PTN_1 .box {background: #d98f00;}
.PTN_2 .box {background: #af2a2e;}
.PTN_3 .box {background: #1d505c;}
.PTN_4 .box {background: #363b72;}
.PTN_5 .box {background: #2b476e;}

.NORMAL {color: #df663a;}
.PTN_1 {color: #d98f00;}
.PTN_2 {color: #af2a2e;}
.PTN_3 {color: #1d505c;}
.PTN_4 {color: #363b72;}
.PTN_5 {color: #2b476e;}

.i-win {color: #e50000;position: relative;}
.i-lose {color: #2a89dd;position: relative;}
.i-win::before {content: '+';}
.i-lose::before {content: '';}

.copy {display: flex;align-items: center;justify-content: center;}
.copybtn {width: 20px;height: 20px;background: url(~@/assets/img/icon_copy.svg) no-repeat;background-size: cover;cursor: pointer;}

.mrl {margin: 0 20px;}
.active .btnPhoneNum {
  display: none;
}
.product-title.group {
  background: #353535;
}
.product-title.group + td {
  color: red;
}
.product-title {
  min-width: 45px;
  background: #353535;
  color: #fff;
  padding: 4px 0;
  vertical-align: middle;
  border-bottom: solid 1px #ddd;
  width: 100px;
}
.totalTable td {
  min-width: 45px;
  height: 25px;
  width: 100px;
}
.search-check {margin: 25px 0 0 100px;display: flex;flex-direction: row;}
.search-check div {display: flex;align-items: center;gap: 2px;}
.search-check input {margin-right: 30px;}
.search-check label {text-align: right;}
.search-check label:first-child {width: auto;}
.text-space {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;max-width: 140px;display: block;}
</style>
